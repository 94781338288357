/* Logo */
.logo{
    color: black;
    text-decoration: none;

}

.logo:hover{
    color: black;
    cursor: pointer;
    text-decoration: underline;
}

.logo:active{
    color: black;
    cursor: pointer;
 }


/* Sidebar CSS */

.icons{
    max-width: 20px;
}

.link-style {
    color: black; /* Simulate link color */
    cursor: pointer; /* Indicate clickable behavior */
}
    
.link-style:hover {
    text-decoration: underline; /* Add slight overline on hover */
}



/* Airdropper CSS */

.button-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.rendered-container{
    margin: 5%;
    float: left;
    flex-wrap: wrap;
    hyphens: auto;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: center; /* Changed to baseline */
    margin-bottom: 1rem;
  }

  .button {
    background-color: #e8e8e8; /* Light background for unselected state */
    border: 1px solid #ccc; /* Border for all buttons */
    padding: 5px 10px; /* Adjust padding as needed */
    cursor: pointer; /* Indicate clickable button */
  }
  
  .button:active {
    background-color: white; /* Highlight color for selected button */
    /* Optional: Add a subtle border to emphasize selection */
    /* border: 1px solid #999; */
  }
  